import APIService from '@/services/APIService.js';

export default {
  getPerfisUsuarios() {
    return APIService.apiCall().get('/perfis-usuarios/get-perfis-usuarios');
  },
  getPerfilUsuarioById(id)
  {
    return APIService.apiCall().get(`/perfis-usuarios/get-perfil-usuario/${id}`);
  },
  getPerfisAtivos()
  {
    return APIService.apiCall().get('/perfis-usuarios/get-perfis-ativos');
  },
  changeStatus(patchData)
  {
    return APIService.apiCall().patch(
      `/perfis-usuarios/change-status`, 
      JSON.stringify(patchData)
    );
  },
  save(postData) {
    return APIService.apiCall().post(
      '/perfis-usuarios/save',
      JSON.stringify(postData)
    );
  }
};
